import request from "@/utils/request.js";

/**
 * @description: 消息列表
 */
export function listMessages(params: any) {
  return request({
    url: "/api/User/ListMessages",
    method: "get",
    params,
  });
}
/**
 * @description: 消息已读
 */
export function readMessage(params: any) {
  return request({
    url: "/api/User/ReadMessage",
    method: "post",
    data: params,
  });
}
/**
 * @description: 删除消息
 */
export function deleteMessage(params: any) {
  return request({
    url: "/api/User/DeleteMessage",
    method: "post",
    data: params,
  });
}